import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStatus } from "../stores/states";
import { useAuth } from "@/stores/useAuthentication";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/signUp',
    alias: "/",
    name: 'SignUp',
    component: () => import("../views/SignUpForm.vue"),
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: () => import("../views/SignInForm.vue"),
  },
  {
    path: "/ApplicationForm",
    name: "ApplicationForm",
    meta: {
      auth: true
    },
    component: () => import("../views/ApplicationForm.vue"),
  },
  {
    path: "/EmailVerify",
    name: "EmailVerify",
    meta: {
      hasEmailVerifyAccess: true
    },
    component: () => import("../views/EmailVerify.vue"),
  },
  {
    path: "/MobileVerify",
    name: "MobileVerify",
    meta: {
      hasConfirmOTPAccess: true
    },
    component: () => import("../views/MobileVerify.vue"),
  },
  // {
  //   path: "/SmsSent",
  //   name: "SmsSent",
  //   component: () => import("../views/SmsSent.vue"),
  // },
  // {
  //   path: "/SendNationalId",
  //   name: "SendNationalId",
  //   component: () => import("../views/SendNationalId.vue"),
  // },
  // {
  //   path: "/SendSelfy",
  //   name: "SendSelfy",
  //   component: () => import("../views/SendSelfy.vue"),
  // },
  // {
  //   path: "/sendSignature",
  //   name: "sendSignature",
  //   component: () => import("../views/SendSignature.vue"),
  // },
  {
    path: "/ApplicationComplete",
    name: "ApplicationComplete",
    meta: {
      hasCanAccessApplicationComplete: true
    },
    component: () => import("../views/ApplicationComplete.vue"),
  },
  {
    path: "/SubmitOnlineApplyComplete",
    name: "SubmitOnlineApplyComplete",
    component: () => import("../views/SubmitOnlineApplyComplete.vue"),
  },
  {
    path: "/OwnerVerifyComplete",
    name: "OwnerVerifyComplete",
    component: () => import("../views/OwnerVerifyComplete.vue"),
  },
  {
    path: "/verify",
    name: "verifyOwner",
    component: () => import("../views/OwnerVerify.vue"),
  },
  {
    path: "/ownerVerified",
    name: "ownerVerified",
    component: () => import("../views/ownerVerified.vue"),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import("../views/NotFound.vue"),
  },
  

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {


  if(to.meta.hasConfirmOTPAccess){    
    const statusStore = useStatus();
    if (statusStore.$state.canAccessConfirmOTP) {
      next()
    }else{
      if(to.hash){
        next(`/signUp${to.hash}`)
      }
      next('/signUp')
    }
  }

  if(to.meta.hasEmailVerifyAccess){

    const statusStore = useStatus();
    if (statusStore.$state.canAccessEmailVerification) {
      next()
    }else{
      if(to.hash){
        next(`/signIn${to.hash}`)
      }
      next('/signIn')
    }
  }

  if(to.meta.hasCanAccessApplicationComplete){

    const statusStore = useStatus();
    if (statusStore.$state.canAccessApplicationComplete) {
      next()
    }else{
      next('/signIn')
    }
  }

  if(to.meta.auth){

    const authStore = useAuth();
    const auth_token = to.query.auth_token
    if (auth_token) {
      authStore.setToken(auth_token as string) 
    }
    authStore.refreshToken()
    if (authStore.$state.auth_token) {
      next()
    }else{
      next('/signIn')
    }
  }
  
  next()
})

export default router;
