import { defineStore } from "pinia";
import { AuthorizedPersonForm, SignUp } from "@/types/global";
import axios from "axios";
import router from "@/router";

export const useState = defineStore("common", {
  state: () => {
    return {
      title: "",
    };
  },
});

export const useStatus = defineStore("eligible", {
  state: () => {
    return {
      canAccessConfirmOTP: false,
      canAccessEmailVerification: false,
      canAccessApplicationComplete: true,
    };
  },
  getters: {},
  actions: {},
  // persist: true, auto save to localStorage
});

export const useEnum = defineStore("enum", {
  state: () => {
    return {
      contractorType: [
        {
          label: "รับเหมาก่อสร้าง",
          value: "normal",
        },
        {
          label: " ร้านค้าช่วง",
          value: "sub_dealer",
        },
        {
          label: "ร้านค้าช่วง (บุคคลธรรมดา)",
          value: "individual",
        }
        // {
        //   label: "Goverment",
        //   value: "government",
        // },
      ],
      knowFrom: [
        {
          label: "เฟซบุ๊กสยาม เซย์ซอน ",
          value: "facebook",
        },
        {
          label: "เว็บไซต์สยาม เซย์ซอน",
          value: "website",
        },
        {
          label: "ร้านค้า",
          value: "store",
        },
        {
          label: "งานแสดงสินค้า",
          value: "tradeShow",
        },
        {
          label: "เพื่อนหรือคนรู้จัก",
          value: "acquaintance",
        },
        {
          label: "อื่นๆ",
          value: "other",
        },

      ],
      ratin: [
        {
          label: "5 (พอใจมาก)",
          value: 5,
        },
        {
          label: "4 (พอใจ)",
          value: 4,
        },
        {
          label: "3 (ปานกลาง)",
          value: 3,
        },
        {
          label: "2 (ไม่พอใจ)",
          value: 2,
        },
        {
          label: "1 (ควรปรับปรุง)",
          value: 1,
        },
      ],
    };
  },
});

export const useImage = defineStore("image", {
  state: () => {
    return {
      nationalIdCardBase64: "",
    };
  },
  getters: {},
  actions: {
    updateNationalId(nationalIdCardBase64: string) {
      this.nationalIdCardBase64 = nationalIdCardBase64;
    },
  },
  persist: true,
});

export const useGoogleTag = defineStore("googleTag", {
  state: () => {
    return {
      googleTag: [
        {
          dealer: 'BTV',
          tag: '#QlRWIG9ubGluZQ==',
          script1link: 'https://www.googletagmanager.com/gtag/js?id=G-D14W3SLTQM',
          script2InnerHTML: 
          `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
  
              gtag('config', 'G-D14W3SLTQM');
          `
      },
      {
          dealer: 'GWH',
          tag: '#R0JIIG9ubGluZQ==',
          script1link: 'https://www.googletagmanager.com/gtag/js?id=G-6E6ZX8VJ79',
          script2InnerHTML: 
          `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-6E6ZX8VJ79');
          `
      }
      ]
    }
  }
})
