import App from './App.vue'
import { createApp } from 'vue'
//PWAにすると最新版が反映されないので暫定的に無効化
//import './registerServiceWorker'
import './styles/app.css';
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createI18n } from 'vue-i18n'
import axios from 'axios'
import VueSignaturePad from 'vue-signature-pad';


//vue3の仕様上VUE_APPのプレフィクスがついてないと読み込まないので注意
axios.defaults.baseURL = process.env.VUE_APP_JV_API_BASE_URL;

const i18n = createI18n({
  legacy: false,
  locale: 'th',
  fallbackLocale: 'en',
  messages: {
    en: require('./locales/en.json'),
    th: require('./locales/th.json')
  }
})

const pinia = createPinia().use(piniaPluginPersistedstate);

createApp(App).use(VueSignaturePad).use(router).use(pinia).use(i18n).mount('#app')