import { defineStore } from "pinia";
import { SignUp } from "@/types/global";
import axios from "axios";
import router from "@/router";

const TOKEN_SESSION = 'auth_token'

export const useAuth = defineStore('auth', {
    state: ()=>{
        return {
            auth_token: undefined,
            signUpForm: {
                th_company_name: undefined,
                tax_id: undefined,
                owner_email: undefined,
                owner_mobile_number: undefined,
              } as SignUp,
              signInForm: {
                tax_id: undefined,
                phone_number: undefined,
                token_otp: undefined
              }
        }
    },
    actions: {
      async signIn(){
          
          try{
            const response = await axios.post('/api/online_apply/sign_in?tax_id=' + this.signInForm.tax_id)
            if(response.data.success){
                this.signInForm.phone_number = response.data.phone_number
                this.signInForm.token_otp = response.data.token
                return true
            }else{
                alert('Error: '+ response.data.error_detail)
                return false
            }

        }catch(error){
            alert('Error: '+ error.message)
            return false
        }
       } ,
       async varifySignIn(){
        try{
            const response = await axios.post('/api/online_apply/verify_sign_in?tax_id=' + this.signInForm.tax_id + '&phone_number=' + this.signInForm.phone_number)
            if(response.data.success){
                this.setToken(response.data.auth_token)
                router.push('/ApplicationForm')
            }else{
                alert('Error: '+ response.data.error_detail)
            }

        }catch(error){
            alert('Error: '+ error.message)
        }
       },
       async sendOtp(){
        try{
            const response = await axios.post('/api/online_apply/send_validation_sms?phone_number=' + this.signInForm.phone_number)

            if(response.data.success){
                this.signInForm.token_otp = response.data.token
                return true
            }else{
                alert('Error: '+ response.data.error_detail)
            return false
            }
        }catch(error){
            alert('Error: '+ error.message)
            return false
        }
       },
       async confirmOtp(otpCode: string){
        try{
            const response = await axios.post('/api/online_apply/validate_passcode?passcode=' + otpCode + '&token=' + this.signInForm.token_otp)

            if(response.data.success){
                this.varifySignIn()
            }else{
                alert('Error: '+ response.data.error_detail)
            }
        }catch(error){
            alert('Error: '+ error.message)
        }
       },
       setToken(token:string){
        sessionStorage.setItem(TOKEN_SESSION,token)
        this.auth_token = token
       },
       refreshToken(){
       const token = sessionStorage.getItem(TOKEN_SESSION)
        if(token){
            this.setToken(token)
        }else{
            router.push('/')
        }
       },
       destroySeseion(){
        sessionStorage.clear()
       }
    }
})